:root {
  --grey-50: hsl(0, 0%, 98%);
  --grey-100: hsl(0, 0%, 96%);
  --grey-200: hsl(0, 0%, 93%);
  --grey-300: hsl(0, 0%, 88%);
  --grey-400: hsl(0, 0%, 74%);
  --grey-500: hsl(0, 0%, 62%);
  --grey-600: hsl(0, 0%, 46%);
  --grey-700: hsl(0, 0%, 38%);
  --grey-800: hsl(0, 0%, 26%);
  --grey-900: hsl(0, 0%, 13%);

  --primary-50: hsl(16, 16%, 93%);
  --primary-100: hsl(16, 16%, 80%);
  --primary-200: hsl(16, 16%, 70%);
  --primary-300: hsl(16, 16%, 56%);
  --primary-400: hsl(16, 18%, 47%);
  --primary-500: hsl(16, 25%, 40%);
  --primary-600: hsl(16, 25%, 34%);
  --primary-700: hsl(16, 26%, 29%);
  --primary-800: hsl(16, 26%, 24%);
  --primary-900: hsl(16, 28%, 19%);
}
