// ===================================
// Checkboxes & Radio buttons
// ===================================

// Common styles
.a-formCheck {
  display: inline-grid;
  align-items: center;
  grid-template-areas: "input";
  & > * {
    grid-area: input;
  }
}

.a-formCheck_label {
  display: inline-grid;
  grid-template-areas: "input label";
  grid-template-columns: auto 1fr;
  gap: 1ch;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &::before, &::after {
    content: "";
    grid-area: input;
    width: 1rem;
    height: 1rem;
    background-color: transparent;
    transition: all .2s ease;
  }
  &::before {
    border: 2px solid var(--input-border-color);
  }
}

.a-formCheck_input {
  all: unset;
  // common focused state
  &:focus + .a-formCheck_label::before {
    border-color: var(--color-primary);
  }
  // common checked state
  &:checked + .a-formCheck_label::before {
    border-color: var(--color-primary);
  }
  // Common disabled state
  &:disabled + .a-formCheck_label {
    color: var(--input-disabled-color);
    cursor: auto;
    &::before {
      border-color: var(--input-disabled-color);
    }
  }
  // checkbox styles
  &[type="checkbox"] {
    &:checked + .a-formCheck_label::before {
      background-color: var(--color-primary);
    }
    &:checked + .a-formCheck_label::after {
      background: var(--text-on-primary);
      width: 2px;
      height: 2px;
      box-shadow:
        2px 0 0 var(--text-on-primary),
        4px 0 0 var(--text-on-primary),
        4px -2px 0 var(--text-on-primary),
        4px -4px 0 var(--text-on-primary),
        4px -6px 0 var(--text-on-primary),
        4px -8px 0 var(--text-on-primary);
      transform: translateX(3px) rotate(45deg);
    }
  }

  // radio styles
  &[type="radio"] {
    & + .a-formCheck_label {
      &::before {
        border-radius: 50%;
      }
      &::after {
        border: 2px solid var(--input-border-color);
        border-radius: 50%;
        background-color: var(--color-primary);
        transform: scale(.5);
        opacity: 0;
      }
    }
    &:checked + .a-formCheck_label::after {
      opacity: 1;
    }
  }
}
