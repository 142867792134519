@layer reboot {

  *, *::before, *::after {
    box-sizing: border-box;
  }

  :root {
    font-size: var(--font-size-root, 16px);
    -moz-tab-size: 4;
    tab-size: 4;
  }

  @media (prefers-reduced-motion: no-preference) {
    :root {
      scroll-behavior: smooth;
    }
  }

  body {
    margin: 0;
    font-family: var(--font-family, sans-serif);
    font-size: var(--font-size-base, inherit);
    line-height: var(--line-height-base, 1.15);
    font-weight: var(--font-weight-base, 400);
    color: var(--text-color, #222);
    text-align: left;
    background-color: var(--body-background, #eee);
    // Prevent adjustments of font size after orientation changes in iOS.
    -webkit-text-size-adjust: 100%;
    // Change the default tap highlight to be completely transparent in iOS.
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  // Future-proof rule: in browsers that support :focus-visible, suppress the focus outline
  // on elements that programmatically receive focus but wouldn't normally show a visible
  // focus outline. In general, this would mean that the outline is only applied if the
  // interaction that led to the element receiving programmatic focus was a keyboard interaction,
  // or the browser has somehow determined that the user is primarily a keyboard user and/or
  // wants focus outlines to always be presented.
  // See https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible
  // and https://developer.paciellogroup.com/blog/2018/03/focus-visible-and-backwards-compatibility/
  [tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
  }


  // ----- Content grouping -----
  hr {
    margin: var(--hr-margin-y, 1rem) 0;
    // Reset Firefox's gray color
    color: var(--hr-color, inherit);
    background-color: currentColor;
    border: 0;
    opacity: var(--hr-opacity, .25);
  }
  hr:not([size]) {
    // Set correct height and prevent the `size` attribute to make the `hr` look like an input field
    height: var(--hr-height, 1px);
  }

  // ----- Headings -----
  // We don't set font-sizes
  :--heading {
    margin: 0 0 1rem;
    font-family: var(--font-family-headings, sans-serif);
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
    color: var(--text-color, #222);
  }

  // ----- paragraphs -----
  p {
    margin: 0 0 1rem;
  }

  // ----- Abbreviations -----
  abbr[title] {
    // Add the correct text decoration in Chrome, Edge, Opera, and Safari.
    text-decoration: underline dotted;
    // Add explicit cursor to indicate changed behavior.
    cursor: help;
    // Prevent the text-decoration to be skipped.
    text-decoration-skip-ink: none;
  }

  // ----- Address -----
  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }

  // ----- Lists -----
  ol, ul, dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  ol, ul {
    padding-left: 2rem;
    & ol, & ul {
      margin-bottom: 0;
    }
  }
  dt {
    font-weight: 500;
  }
  dd {
    margin-bottom: .5rem;
    margin-left: 0;
  }

  // ----- Blockquote -----
  blockquote {
    margin: 0 0 1rem;
  }

  // ----- Strong -----
  b, strong {
    font-weight: var(--font-weight-bolder, bolder);
  }

  // ----- Small -----
  small {
    font-size: var(--small-font-size, .875em);
  }

  // ----- Mark -----
  mark {
    padding: var(--mark-padding, .2em);
    background-color: var(--mark-bg, #fcf8e3);
  }

  // ----- Sub & Sup -----
  // Prevent `sub` and `sup` elements from affecting the line height in all browsers.
  sub, sup {
    position: relative;
    font-size: var(--sub-sup-font-size, .75em);
    line-height: 0;
    vertical-align: baseline;
  }
  sub { bottom: -.25em; }
  sup { top: -.5em; }

  // ----- Links -----
  a {
    color: var(--link-color);
    text-decoration: var(--link-decoration, none);

    &:hover {
      color: var(--link-hover-color);
      text-decoration: var(--link-hover-decoration, none);
    }
  }
  // And undo these styles for placeholder links/named anchors (without href).
  // It would be more straightforward to just use a[href] in previous block, but that
  // causes specificity issues in many other styles that are too complex to fix.
  // See https://github.com/twbs/bootstrap/issues/19402
  a:not([href]):not([class]) {
    &, &:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  // ----- Code -----
  pre, code, kbd, samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    // Correct the odd `em` font sizing in all browsers.
    font-size: 1em;
    direction: ltr;
    unicode-bidi: bidi-override;
  }

  pre {
    display: block;
    // Remove browser default top margin
    margin-top: 0;
    // Reset browser default of `1em` to use `rem`s
    margin-bottom: 1rem;
    // Don't allow content to break outside
    overflow: auto;
    font-size: var(--code-font-size, .875em);
    color: var(--pre-color, inherit);

    // Account for some code outputs that place code tags in pre tags
    code {
      font-size: inherit;
      color: inherit;
      word-break: normal;
    }
  }

  code {
    font-size: var(--code-font-size, .875em);
    color: var(--code-color, inherit);
    word-wrap: break-word;

    // Streamline the style when inside anchors to avoid broken underline and more
    a > & {
      color: inherit;
    }
  }

  kbd {
    padding: var(--kbd-padding-y, .2rem) var(--kbd-padding-x, .4rem);
    font-size: var(--kbd-font-size, .875rem);
    color: var(--kbd-color, inherit);
    background-color: var(--kbd-bg, inherit);
    border-radius: 0;

    kbd {
      padding: 0;
      font-size: 1em;
      font-weight: var(--nested-kbd-font-weight, 500);
    }
  }

  // ----- Figures -----
  // Apply a consistent margin strategy (matches our type styles).
  figure {
    margin: 0 0 1rem;
  }


  // ----- Images and content -----
  img, svg {
    vertical-align: middle;
  }


  // ----- Tables -----
  // Prevent double borders
  table {
    caption-side: bottom;
    border-collapse: collapse;
  }
  caption {
    padding-top: var(--table-cell-padding-y, .5rem);
    padding-bottom: var(--table-cell-padding-y, .5rem);
    color: var(--table-caption-color, var(--text-muted));
    text-align: left;
  }

  th {
    // 1. Removes font-weight bold by inheriting
    font-weight: var(--table-th-font-weight, inherit);
    // 2. Matches default `<td>` alignment by inheriting `text-align`.
    text-align: inherit;
    // 3. Fix alignment for Safari
    text-align: -webkit-match-parent;
  }

  thead, tbody, tfoot, tr, td, th {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }


  // ----- Forms -----

  label {
    // 1. Allow labels to use `margin` for spacing.
    display: inline-block;
  }

  button {
    // Remove the default `border-radius` that macOS Chrome adds.
    // See https://github.com/twbs/bootstrap/issues/24093
    border-radius: 0;
  }

  // Work around a Firefox bug where the transparent `button` background
  // results in a loss of the default `button` focus styles.
  // Credit https://github.com/suitcss/base/
  button:focus {
    outline: dotted 1px;
    outline: -webkit-focus-ring-color auto 5px;
  }

  input,
  button,
  select,
  optgroup,
  textarea {
    // 1. Remove the margin in Firefox and Safari
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button,
  select {
    // Remove the inheritance of text transform in Firefox
    text-transform: none;
  }

  // Set the cursor for non-`<button>` buttons
  // Details at https://github.com/twbs/bootstrap/pull/30562
  [role="button"] {
    cursor: pointer;
  }

  // Remove the inheritance of word-wrap in Safari.
  // See https://github.com/twbs/bootstrap/issues/24990
  select {
    word-wrap: normal;
  }

  // Remove the dropdown arrow in Chrome from inputs built with datalists.
  // See https://stackoverflow.com/a/54997118
  [list]::-webkit-calendar-picker-indicator {
    display: none;
  }

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    // 2. Correct the inability to style clickable types in iOS and Safari.
    -webkit-appearance: button;

    &:not(:disabled) {
      cursor: pointer;
    }
  }

  // Remove inner border and padding from Firefox, but don't restore the outline like Normalize.
  ::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }

  textarea {
    // Textareas should really only resize vertically so they don't break their (horizontal) containers.
    resize: vertical;
  }

  // Browsers set a default `min-width: min-content;` on fieldsets,
  //   unlike e.g. `<div>`s, which have `min-width: 0;` by default.
  //   So we reset that to ensure fieldsets behave more like a standard block element.
  //   See https://github.com/twbs/bootstrap/issues/12359
  //   and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
  fieldset {
    min-width: 0;
    // Reset the default outline behavior of fieldsets so they don't affect page layout.
    padding: 0;
    margin: 0;
    border: 0;
  }

  legend {
    // By using `float: left`, the legend will behave like a block element.
    // This way the border of a fieldset wraps around the legend if present.
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: var(--legend-margin-bottom, .5rem);
    font-size: var(--legend-font-size, 1.5rem);
    font-weight: var(--legend-font-weight, inherit);
    line-height: inherit;

    & + * {
      // 2. Fix wrapping bug. See https://github.com/twbs/bootstrap/issues/29712
      clear: left;
    }
  }

  // Fix height of inputs with a type of datetime-local, date, month, week, or time
  // See https://github.com/twbs/bootstrap/issues/18842
  ::-webkit-datetime-edit-fields-wrapper,
  ::-webkit-datetime-edit-text,
  ::-webkit-datetime-edit-minute,
  ::-webkit-datetime-edit-hour-field,
  ::-webkit-datetime-edit-day-field,
  ::-webkit-datetime-edit-month-field,
  ::-webkit-datetime-edit-year-field {
    padding: 0;
  }

  ::-webkit-inner-spin-button {
    height: auto;
  }

  [type="search"] {
    // 1. Correct the outline style in Safari.
    outline-offset: -2px;
    // This overrides the extra rounded corners on search inputs in iOS so that our
    // `.form-control` class can properly style them. Note that this cannot simply
    // be added to `.form-control` as it's not specific enough.
    // For details, see https://github.com/twbs/bootstrap/issues/11586.
    -webkit-appearance: textfield;
  }

  ::-webkit-search-decoration {
    // Remove the inner padding in Chrome and Safari on macOS.
    -webkit-appearance: none;
  }

  ::-webkit-color-swatch-wrapper {
    // Remove padding around color pickers in webkit browsers
    padding: 0;
  }

  ::file-selector-button {
    // Inherit font family and line height for file input buttons
    font: inherit;
  }

  ::-webkit-file-upload-button {
    // Change font properties to `inherit`
    font: inherit;
    // Correct the inability to style clickable types in iOS and Safari.
    -webkit-appearance: button;
  }

  // Correct element displays
  output {
    display: inline-block;
  }

  // Remove border from iframe
  iframe {
    border: 0;
  }

  // ----- Summary -----
  summary {
    // 1. Add the correct display in all browsers
    display: list-item;
    cursor: pointer;
  }
  summary:focus:not(:focus-visible) {
    outline: none;
  }


  // ----- Progress -----
  progress {
    // Add the correct vertical alignment in Chrome, Firefox, and Opera.
    vertical-align: baseline;
  }

  // ----- Hidden attribute -----
  [hidden] {
    display: none !important;
  }

}
