@mixin button-base {
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 1ch;
  font-family: var(--btn-font-family, inherit);
  font-size: var(--btn-font-size, inherit);
  line-height: var(--btn-line-height, inherit);
  color: var(--btn-text-color, inherit);
  background-color: var(--btn-color, transparent);
  border: var(--btn-border-width, 1px) solid transparent;
  cursor: pointer;
  padding: var(--btn-padding, 4px);
  text-transform: uppercase;
  text-decoration: none;
  transition-property: background-color, border-color, color;
  transition-duration: .15s;
  transition-timing-function: var(--standard-curve-timing-function);

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:hover, &:focus {
    color: var(--btn-text-color, inherit);
    background-color: var(--btn-hover-color, var(--btn-color, transparent));
    text-decoration: unset;
  }
  &:disabled, &.-disabled, fieldset:disabled & {
    background-color: var(--btn-disabled-color);
    cursor: initial;
    pointer-events: none;
  }
}

@mixin button-outlined {
  color: var(--btn-color);
  border-color: var(--btn-color);
  background-color: transparent;
  &:hover, &:focus {
    color: var(--btn-text-color);
    background-color: var(--btn-color);
    border-color: transparent;
  }
  &:disabled, &.-disabled, fieldset:disabled & {
    color: var(--btn-disabled-color, gray);
    border-color: var(--btn-disabled-color, gray);
    background-color: transparent;
  }
}

@mixin button-text {
  color: var(--btn-color, inherit);
  background-color: transparent;
  &:hover, &:focus {
    color: var(--btn-hover-color, inherit);
    background-color: transparent;
  }
  &:disabled, &.-disabled, fieldset:disabled & {
    color: var(--btn-disabled-color, gray);
  }
}

@mixin button-primary {
  --btn-color: var(--btn-primary-color);
  --btn-hover-color: var(--btn-primary-hover-color);
  --btn-text-color: var(--btn-primary-text-color);
}

@mixin button-secondary {
  --btn-color: var(--btn-secondary-color);
  --btn-hover-color: var(--btn-secondary-hover-color);
  --btn-text-color: var(--btn-secondary-text-color);
}

@mixin button-danger {
  --btn-color: var(--color-danger);
  --btn-hover-color: var(--color-danger);
  --btn-text-color: var(--text-on-danger);
}

button[type="submit"],
input[type="button"],
input[type="submit"],
input[type="reset"] {
  @include button-base;
}

input[type="submit"],
button[type="submit"]
{
  @include button-primary;
}

button[type="button"],
button[type="reset"],
input[type="reset"]
{
  @include button-secondary;
}

.a-btn, a.a-btn {

  @include button-base;

  &.-primary {
    @include button-primary;
  }
  &.-secondary {
    @include button-secondary;
  }
  &.-danger {
    @include button-danger;
  }

  &.-outlined {
    @include button-outlined;
  }

  &.-text {
    @include button-text;
  }
}

