@use "sass:map";

$breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

@each $key, $value in $breakpoints {
  @custom-media --viewport-#{$key}-up (min-width: #{$value});
  @custom-media --viewport-#{$key}-down (max-width: #{$value});
}

:root {
  // Pass breakpoints to javascript
  @each $key, $value in $breakpoints {
    --breakpoint-#{$key}: #{$value};
  }
}

@mixin between($min, $max) {
  @media (map.get($breakpoints, $min) <= width < map.get($breakpoints, $max)) {
    @content;
  }
}
