
input, textarea {
  background-color: var(--grey-50);
  border: 1px solid var(--grey-400);
  border-radius: 0;
  padding: .375rem .75rem;
  transition: border-color .15s var(--standard-curve-timing-function);

  &:focus-visible {
    outline: none;
    border-color: var(--color-primary-light);
  }

  &::placeholder {
    color: var(--text-muted);
    opacity: 1;
  }

  &:disabled, &:read-only {
    background-color: var(--text-muted);
    border-color: var(--grey-600);
    opacity: 1;
  }
}

input {
  &[type="radio"], &[type="checkbox"] {
    border-color: transparent;
  }
  &::-webkit-date-and-time-value {
    // https://bugs.webkit.org/show_bug.cgi?id=198959
    height: var(--line-height-base);
  }
}

select {
  appearance: none;
  cursor: pointer;
  background-color: var(--grey-50);
  background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 1"><path d="M.691.345L.5.537.309.345.25.404.5.655.75.404z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 0.125em top calc(50% + .125em);
  background-size: 1em 1em;
  padding: .375em 1.125em .375em .75em;
  border: 1px solid var(--grey-400);
  transition: border-color .15s var(--standard-curve-timing-function);

  & option {
    font-weight: normal;
  }

  &::-ms-expand {
    // Hide arrow icon in IE browsers
    display: none;
  }
  &:disabled, &[aria-disabled=true] {
    opacity: 0.5;
    cursor: auto;
  }
  &:focus-visible {
    outline: none;
    border-color: var(--color-primary-light);
  }
  // Support for rtl text, explicit support for Arabic and Hebrew
  [dir=rtl] &,
  :root:lang(ar) &,
  :root:lang(iw) & {
    background-position: left 0.125em top calc(50% + .125em);
    padding: .375em .75em .375em 1.125em;
  }
}
