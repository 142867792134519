:root {
  --shape-checkmark: polygon(
    12.5% 54.8%,
    18.6% 48.7%,
    36.3% 66.5%,
    81.5% 21.4%,
    87.5% 27.4%,
    36.3% 78.6%
  );
  --shape-checkbox-mark: polygon(
    20.8% 50%,
    26.7% 44.1%,
    41.7% 59%,
    73.3% 27.4%,
    79.2% 33.3%,
    41.7% 70.8%
  );
}
