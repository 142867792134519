@use "./medias";
@use "./selectors";
@use "./colors";
@use "./elevation";
@use "./animation";
@use "./shapes";

@layer reboot, atoms, molecules, organisms, pages, utilities;

:root {
  // Typography
  --font-family: Lato, sans-serif;
  --font-size-root: 16px;
  --font-size-base: 14px;
  --line-height-base: 1.5rem;
  --font-weight-base: 400;
  --font-weight-bolder: 400;
  --font-family-headings: Jost, sans-serif;

  --body-background: hsl(16, 8%, 99%);
  --text-color: hsl(0, 0%, 13%);
  --text-muted: hsl(0, 0%, 60%);
  --text-light: hsl(0, 0%, 80%);

  //--color-primary-dark: hsl(16, 42%, 18%);
  --color-primary-dark: var(--primary-900);
  --color-primary: var(--primary-600);
  --color-primary-light: var(--primary-300);
  --text-on-primary: #fff;

  accent-color: var(--color-primary);

  --color-secondary: hsl(0, 0%, 13%);
  --text-on-secondary: #fff;

  --color-black: hsl(0, 0%, 20%);

  --color-danger: hsl(0, 70%, 57%);
  --text-on-danger: #fff;

  --color-warning: hsl(43, 70%, 57%);
  --text-on-warning: #000;

  --color-info: hsl(200, 63%, 47%);
  --text-on-info: #fff;

  --color-success: hsl(134, 61%, 41%);
  --text-on-success: #fff;

  --dark-background-color: hsl(16, 8%, 16%);
  --text-on-dark-background: #fff;
  --link-on-dark-background: var(--primary-300);
  --link-hover-on-dark-background: var(--color-primary);

  --link-color: var(--color-primary);
  --link-hover-color: var(--color-primary-dark);

  --input-border-color: hsl(0, 0%, 56%);
  --input-disabled-color: hsl(0, 0%, 66%);

  --logo-color: black;
  --logo-thread-color: var(--color-primary);

  --site-nav-bg-color: var(--dark-background-color);
  --site-nav-text-color: var(--text-on-dark-background);
  --site-nav-link-color: var(--text-on-dark-background);
  --site-nav-link-color-hover: hsl(16, 20%, 62%);
  --site-nav-submenu-link-color: var(--text-muted);
  --site-nav-submenu-link-color-hover: var(--color-primary-dark);

  --footer-background: var(--dark-background-color);
  --footer-text-color: var(--text-on-dark-background);
  --footer-link-color: hsl(16, 16%, 60%); //var(--link-on-dark-background);

  --btn-font-family: inherit;
  --btn-font-size: inherit;
  --btn-font-weight: normal;
  --btn-line-height: 1;
  --btn-padding: .5rem .75rem;
  --btn-border-width: 1px;
  --btn-color: var(--text-color);
  --btn-text-color: var(--body-background);
  --btn-disabled-color: gray;
  --btn-hover-color: var(--text-color);

  --btn-primary-color: var(--color-primary);
  --btn-primary-hover-color: var(--color-primary-dark);
  --btn-primary-text-color: var(--text-on-primary);

  --btn-secondary-color: var(--color-secondary);
  --btn-secondary-hover-color: var(--color-secondary);
  --btn-secondary-text-color: var(--text-on-secondary);

  --mark-bg: var(--primary-50);
}
