@use "~@material/elevation/elevation-theme";

:root {
  --z-index-appbar: 4;
  --z-index-dialog: 7;

  @for $z from 0 through 24 {
    --elevation-z#{$z}: #{elevation-theme.elevation-box-shadow($z)};
  }

  --elevation-std-sheet: var(--elevation-z0);
  --elevation-btn: var(--elevation-z2);
  --elevation-btn-pressed: var(--elevation-z8);
  --elevation-btn-text: var(--elevation-z0);
  --elevation-fab: var(--elevation-z6);
  --elevation-fab-pressed: var(--elevation-z12);
  --elevation-switch: var(--elevation-z1);
  --elevation-card: var(--elevation-z1);
  --elevation-card-hover: var(--elevation-z8);
  --elevation-search-bar: var(--elevation-z1);
  --elevation-appbar: var(--elevation-z0);
  --elevation-appbar-scrolled: var(--elevation-z4);
  --elevation-menu: var(--elevation-z8);
  --elevation-bottom-appbar: var(--elevation-z8);
  --elevation-drawer: var(--elevation-z16);
  --elevation-modal: var(--elevation-z16);
  --elevation-dialog: var(--elevation-z24);

  --elevation-bottom-bar:
    0 -2px 2px -2px rgb(0 0 0 / 8%),
    0 -4px 4px -4px rgb(0 0 0 / 10%),
    0 -8px 8px -8px rgb(0 0 0 / 14%)
  ;
}
