
@mixin visually-hidden() {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  // Fix for https://github.com/twbs/bootstrap/issues/25686
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.u-hidden,
.u-hidden-focusable:not(:focus):not(:focus-within),
.screen-reader-text
{
  @include visually-hidden();
}
