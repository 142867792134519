@use "./base/settings";
@use "./base/reboot";
@use "./atoms/forms";
@use "./atoms/buttons";
@use "./utilities/visually-hidden";

html, body {
  height: 100vh;
}
body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (--viewport-md-up) {
    gap: 2rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.button {
  @include buttons.button-base;
  &.wp-hide-pw {
    @include buttons.button-outlined;
  }
}

h1 {
  padding: .5rem 0;
  a {
    display: block;
    height: 100px;
    text-indent: -9999px;
    background: url("../img/logo.svg") center / contain no-repeat;
    @media (--viewport-md-up) {
      height: 120px;
    }
  }
}

form, .message, #login_error, #nav, #backtoblog, .privacy-policy-page-link {
  padding: 1rem;
  margin: 0;
  @media (--viewport-md-up) {
    padding: 1rem 2rem;
  }
}

.message {
  background-color: var(--color-primary);
  color: var(--text-on-primary);
}

#login_error {
  background-color: var(--color-danger);
  color: var(--text-on-danger);
  font-size: 18px;
  a {
    color: var(--text-on-danger);
    text-decoration: underline;
  }
}

#login {
  width: 100vw;
  height: 100vh;
  max-width: 576px;

  @media (--viewport-sm-up) {
    width: auto;
    height: auto;
    box-shadow: var(--elevation-dialog);
  }

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: dense;
  column-gap: 1rem;

  & > * {
    grid-column: 1 / -1;
  }
}

#backtoblog {
  grid-column: 1;
  justify-self: start;
}
#nav {
  grid-column: 2;
  justify-self: end;
}


.login-action-login {
  #loginform {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 1.5rem 1rem;
    padding: 1rem;
    color: var(--grey-600);

    & > * {
      grid-column: 1 / span 2;
      display: flex;
      flex-direction: column;
      margin: 0;
    }
    .submit, .forgetmenot {
      grid-column: auto;
      flex-direction: row;
      align-items: center;
    }
    .forgetmenot {
      justify-content: flex-start;
      label {
        margin-left: 1ch;
      }
    }
    .submit {
      justify-content: flex-end;
    }
    @media (--viewport-md-up) {
      padding: 2rem;
    }
  }

  .wp-pwd {
    display: grid;
    grid-template-columns: 1fr auto;
  }

  #wp-submit {
    padding-top: .75rem;
  }

  #rememberme {
    height: 1em;
    width: 1em;
  }
}

.login-action-confirm_admin_email {
  .admin-email__heading {
    @extend .message;
    font-size: 1.25rem;
    text-align: center;
    margin-bottom: 1rem;
  }
  .admin-email__actions {
    &, &-primary {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }
}

// TODO: check these !
.login-action-checkemail {}
.login-action-confirm_admin_email {}
.login-action-confirmaction {}
.login-action-entered_recovery_mode {}
.login-action-login {}
.login-action-logout {}
.login-action-lostpassword {}
.login-action-postpass {}
.login-action-register {}
.login-action-resetpass {}
.login-action-retrievepassword {}
.login-action-rp {}
